<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
// import PageHeader from "@/components/page-header";
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

import flatPickr from "vue-flatpickr-component";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "@/components/widgets/dropZone";

// import Pickr from "@simonwep/pickr";

import "flatpickr/dist/flatpickr.css";
import "@simonwep/pickr/dist/themes/classic.min.css"; // 'classic' theme
import "@simonwep/pickr/dist/themes/monolith.min.css"; // 'monolith' theme
import "@simonwep/pickr/dist/themes/nano.min.css";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import { mapState } from "vuex";
import axios from "axios";
import Paginate from "vuejs-paginate-next";

import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: "Projects",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "Projects",
            items: [
                {
                    text: "Projects",
                    href: "/projects",
                },
                {
                    text: "Projects",
                    active: true,
                },
            ],
            value: ["javascript"],
            rootulr: appConfig.api_url,
            imgsrc: "",
            date: null,
            root_url: appConfig.api_url,
            login_user_work_type: localStorage.getItem('work_type_string'),
            session_usertype: localStorage.getItem('usertype'),
            login_user_work_type_array: JSON.parse(localStorage.getItem('work_type_array')),
            submitted: false,
            regError: null,
            regSuccess: null,
            tryingToRegister: false,
            isRegisterError: false,
            registerSuccess: false,
            userserror: false,
            userserrormsg: "",
            usersok: false,
            is_admin: false,
            usersokmsg: "",
            userSearchTerm: "",
            currentpage: 0,
            totalpage: 0,
            totalrows: 0,

            updateuser_id: 0,
            payment_notes:"",
            usertype: "user",
            userstatus: "",
            userslist: [],

            payment_holders_name_list: [],
            payment_recieved: 0,
            payment_accounts_list: [],
            payment_status: "-1",
            payment_holder_name_id: -1,
            payment_account_id: -1,

            passerror: false,
            passok: false,
            passerrormsg: "",
            passokmsg: "",

            status: "Active",
            university: "",
            deal_by: "",
            bonus: 0,
            isadd: true,
            modaltitle: "",
            buttonname: "",

            profiles: [],
            currency: "USD",
            search_university: "",
            search_deal_by: "",
            amount: "0",
            profile_id: "-1",
            client_name: "",
            client_contact: "",
            client_email: "",
            project_title: "",
            due_date: "",
            project_completion_date: "",
            country: "Saudi Arab",
            work_type: -1,
            words: "0",
            file: null,
            files: [],
            ref_style: "APA",
            attachment_name: "",
            description: "",
            dateTimeConfig: {
                enableTime: true,
                dateFormat: "Y-m-d H:i:s",
            },
            editor: ClassicEditor,
            content: "<h1>Some initial content</h1>",
            plugins: [
                "advlist autolink link lists charmap print preview hr anchor pagebreak spellchecker",
                "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                "save table contextmenu directionality emoticons template paste textcolor",
            ],
            toolbar:
                "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | print preview media fullpage | forecolor backcolor emoticons",
            options: {
                height: 200,
                style_formats: [
                    { title: "Bold text", inline: "b" },
                    { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
                    { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
                    { title: "Example 1", inline: "span", classes: "example1" },
                    { title: "Example 2", inline: "span", classes: "example2" },
                    { title: "Table styles" },
                    { title: "Table row 1", selector: "tr", classes: "tablerow1" },
                ],
            },
            progress_status_filter: "-1",
            from_date: "",
            to_date: "",
            order_by: "createdat",
            sort_by: "DESC",
            clients: {},
            chatData: [],
            chatMessagesData: [],
             config: {
                dateFormat: 'Y-m-d H:i',
                enableTime: true,
                time_24hr: true
            },
        };
    },
    validations: {
        user: {
            username: {
                required: helpers.withMessage("User name is required", required),
            },

            email: {
                required: helpers.withMessage("Email is required", required),
                email: helpers.withMessage("Please enter valid email", email),
            },
        },
    },
    computed: {

    },
    components: {
        Layout,
        paginate: Paginate,
        
        flatPickr,
        ckeditor: CKEditor.component,
        DropZone,
        //Multiselect,
        //flatPickr
    },
    mounted: function () {
        // console.log (this.login_user_work_type_array);
        this.getUserData();
    },
    methods: {
        chatUsername(index) {
            this.username = index;
            // alert(index);
            if (index === -1) {
                this.chatMessagesData = this.chatData;
                this.currentselectedchatname = "All";
                this.currentselecteddatetime = "";
            } else {
                var a = [this.chatData[index]];
                this.chatMessagesData = a;
                // alert(JSON.stringify(this.chatMessagesData))
                this.currentselectedchatname = this.chatData[index].progress_status;
                this.currentselecteddatetime = this.chatData[index].customecreatedat;
            }
        }, async deleteproject(id) {

            let text = "Do you want to DELETE Project having ID="+ id +" ?";
            if (confirm(text) == true) {
              
                await axios.post(appConfig.api_url + 'project/deleteProject/'+id);
                this.usersok = true;
                this.usersokmsg = 'Proejct Deleted'
                this.getUserData();
            }
        },
        deleteRecord(index) {
            this.files.splice(index, 1);
        },
        async opensession(project_id) {
            var result = await axios.post(appConfig.api_url + "project/getsession", {
                project_id: project_id,
            });

            this.chatData = result.data.data.chatsession;
            this.chatMessagesData = this.chatData;
            //alert(JSON.stringify(this.chatData));
            //console.log(users);

            this.project_id = project_id;

            document.getElementById("opensessionbutton").click();
        },
        async delete_single_session(session_id) {
            // alert(session_id);
            var result = await axios.post(
                appConfig.api_url + "project/delete_single_session",
                {
                    session_id: session_id,
                }
            );

            // alert(JSON.stringify(result.data));

            result = await axios.post(appConfig.api_url + "project/getsession", {
                project_id: this.project_id,
            });

            this.chatData = result.data.data.chatsession;
            this.chatMessagesData = this.chatData;
        },
        async getUserData() {
            var result = await axios.post(appConfig.api_url + "project/getall", {
                filter: "" + this.userSearchTerm,
                page: this.currentpage,
                work_type_string: this.login_user_work_type
            });

            var users = result.data.data.user_res;
            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;
            this.payment_holders_name_list = result.data.payment_holder_name;
            this.clients = result.data.clients;
            this.profiles = result.data.profiles;

           
            //  alert(this.profiles)
            this.userslist = users;

            this.from_date = "";
            this.to_date = "";
            this.progress_status_filter = "-1";
            this.payment_holder_name_id = "-1";
            this.payment_account_id = "-1";
            this.payment_status = "-1";
            this.client_name = "";
            this.order_by = "createdat";
            this.sort_by = "DESC";
            this.work_type = "-1";
            this.profile_id = "-1";
            this.search_deal_by = "";
            this.search_university = "";
        },
        async filter_data() {
            var result = await axios.post(
                appConfig.api_url + "project/getall_filter",
                {
                    from_date: this.from_date+":00",
                    to_date: this.to_date+":59",
                    progress_status_filter: this.progress_status_filter,
                    payment_holder_name_id: this.payment_holder_name_id,
                    payment_account_id: this.payment_account_id,
                    payment_status: this.payment_status,
                    client_name: this.client_name,
                    order_by: this.order_by,
                    sort_by: this.sort_by,
                    work_type: parseInt(this.work_type),
                    profile_id: this.profile_id,
                    page: this.currentpage,
                    work_type_string: this.login_user_work_type,
                    search_deal_by: this.search_deal_by,
                    search_university:this.search_university
                }
            );

            var users = result.data.data.user_res;
            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;
            this.payment_holders_name_list = result.data.payment_holder_name;
            this.clients = result.data.clients;
            this.profiles = result.data.profiles;
            this.userslist = users;
        },
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        onselect() {
            this.file = this.$refs.file.files[0];

            //alert("on select ="+this.file);
        },
        async updateuserprofile() {
            this.submitted = true;
            // stop here if form is invalid

            this.tryingToRegister = true;
            this.regError = null;
            var goto = "project/update";
            if (this.isadd) {
                goto = "project/create";
            }

            // alert(appConfig.api_url + '' + goto);
            let date_ob = new Date();
            let date = ("0" + date_ob.getDate()).slice(-2);
            let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
            let year = date_ob.getFullYear();
            let hours = date_ob.getHours();
            let minutes = date_ob.getMinutes();
            let seconds = date_ob.getSeconds();
            let millisecond = date_ob.getMilliseconds();

            var idatetime =
                year +
                "" +
                month +
                "" +
                date +
                "" +
                hours +
                "" +
                minutes +
                "" +
                seconds +
                "" +
                millisecond;
            // alert(this.file);
            //alert(appConfig.api_url + '' + goto);
            //    {
            //         status: this.status,
            //         currency: this.currency,
            //         amount: this.amount,
            //         profile_id: this.project_id,
            //         client_name: this.client_name,
            //         client_contact: this.client_contact,
            //         Client_email: this.Client_email,
            //         project_title: this.project_title,
            //         due_date: this.due_date,
            //         project_completion_date: this.project_completion_date,
            //         country: this.country,
            //         work_type: this.work_type,
            //         words: this.words,
            //         ref_style: this.ref_style,
            //         attachment_name: idatetime,
            //         description: this.description,
            //         file: this.file,
            //         createdby:localStorage.getItem('userid')
            //     }
            let formData = new FormData();

            formData.append("status", this.status);
            formData.append("currency", this.currency);
            formData.append("amount", this.amount);
            formData.append("profile_id", this.profile_id);
            formData.append("client_name", this.client_name);
            formData.append("client_contact", this.client_contact);
            formData.append("client_email", this.client_email);
            formData.append("project_title", this.project_title);
            formData.append("office_completion_date", this.project_completion_date);
            formData.append("country", this.country);
            formData.append("work_type", this.work_type);
            formData.append("words", this.words);
            formData.append("university", this.university);
            formData.append("deal_by", this.deal_by);
            formData.append("bonus", this.bonus);
            formData.append("payment_notes", this.payment_notes);
            
            formData.append("ref_style", this.ref_style);
            formData.append("attachment_name", idatetime);
            formData.append("description", this.description);
            formData.append("createdby", localStorage.getItem("userid"));
            formData.append("updatedby", localStorage.getItem("userid"));
            formData.append("id", this.updateuser_id);

            console.log("profiles=" + JSON.stringify(this.profiles));
            var code="";
            for (var i = 0; i < this.profiles.length; i++) {
                var ab = this.profiles[i];
                if (this.profile_id === ab.id) {
                   
                    // // reverse the profile type 
                    // let reversetype = "";
                    // for (let j = ab.type.length - 1; j >= 0; j--) {
                    //     reversetype += ab.type[j];
                    // }
                    code = ab.full_name + "" + ab.short_name;
                    //console.log("P code ===="+code);

                }
            }
            formData.append("code", code);
            // formData.append("file", this.file);

            for (
                var k = 0;
                k < document.querySelector(".dropzoneFile").files.length;
                k++
            ) {
                let filei = document.querySelector(".dropzoneFile").files[k];
                //formData.append('files[' + i + ']', file);
                // dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
                //  this.file.push(filei);
                // this.file.push(filei);
                formData.append("file", filei);
                // files.value.push(dropzoneFile.value);
                //console.log("====total files===" + k);
            }
            formData.append("due_date", this.due_date);

            formData.append("payment_account_id", this.payment_account_id);
            formData.append("payment_holder_name_id", this.payment_holder_name_id);
            formData.append("payment_recieved", this.payment_recieved);
            formData.append("payment_status", this.payment_status);

            console.log(this.file);

            const result = await axios.post(appConfig.api_url + "" + goto, formData);

            // alert(JSON.stringify(result.data));

            if (result.data.status == "errors") {
                this.tryingToRegister = false;
                this.regError = result.data.data.data;
                this.isRegisterError = true;
                // return this.authError = result.data.data.data;
            } else {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                this.regSuccess = result.data.data.data;
                this.getUserData();
                document.getElementById("closeupdatemodalbutton").click();
                // localStorage.setItem('username', this.user.name)
                // localStorage.setItem('useremail', this.user.useremail)
                // //   alert(result.data.data.data);
                //  console.log(result.data.data.data);
            }
            this.submitted = false;
        },
        async filteruser() {
            this.userslist = [];
            this.getUserData();
        },
        async updateuser(
            id,
            profile_id,
            client_name,
            client_contact,
            client_email,
            project_title,
            amount,
            currency,
            due_date,
            office_completion_date,
            country,
            work_type,
            words,
            ref_style,
            status,
            description,
            payment_holder_name_id,
            payment_recieved,
            payment_status,
            payment_account_id,
            university,
            deal_by,
            bonus
        ) {
            this.modaltitle = "Update Project";
            this.buttonname = "Update";
            this.isadd = false;
            this.payment_accounts_list = [];

            this.payment_holder_name_id = payment_holder_name_id;
            await this.paymentaccounts();
            this.payment_recieved = payment_recieved;
            this.payment_status = payment_status;
            this.payment_account_id = payment_account_id;

            this.updateuser_id = id;
            this.profile_id = profile_id;
            this.client_name = client_name;
            this.client_contact = client_contact;
            this.client_email = client_email;
            this.project_title = project_title;
            this.amount = amount;
            this.currency = currency;
            this.due_date = due_date;
            this.project_completion_date = office_completion_date;
            this.country = country;
            this.work_type = work_type;
            this.words = words;
            this.ref_style = ref_style;
            this.status = status;
            this.university = university;
            this.deal_by = deal_by;
            this.bonus = bonus;
            this.description = description;
            this.files = [];

            document.getElementById("openupdatemodalbutton").click();
            this.isRegisterError = false;
            this.registerSuccess = false;
        },
        createuser() {
            this.payment_holder_name_id = -1;
            this.payment_recieved = 0;
            this.payment_status = "Not Paid";
            this.payment_account_id = 0;
            this.payment_accounts_list = [];
            this.isRegisterError = false;
            this.registerSuccess = false;
            this.modaltitle = "Create new Project";
            this.buttonname = "Add";
            this.isadd = true;
            this.client_name = "";
            this.client_contact = "";
            this.client_email = "";
            this.project_title = "";
            this.amount = 0;
            this.words = 0;
            this.due_date = "";
            this.project_completion_date = "";
            this.work_type = "Writing";
            this.profile_id = -1;
            this.updateuser_id = "-1";
            this.full_name = "";
            this.short_name = "";
            this.type = "";
            this.university = "";
            this.deal_by = "";
            this.bonus = 0;
            this.status = "Active";
            this.url = "";
            this.contact = "";
            this.files = [];
            this.payment_notes = "";
            document.getElementById("openupdatemodalbutton").click();
        },
        async paymentaccounts() {
            // alert(this.payment_holder_name_id);
            var result = await axios.post(
                appConfig.api_url + "project/get_single_payment_accounts",
                {
                    payment_holder_name_id: this.payment_holder_name_id,
                }
            );
            // alert(result.data);
            this.payment_accounts_list = result.data.data;
        },
        selectedFile() {
            //alert("called selectedfiles");
            for (
                var i = 0;
                i < document.querySelector(".dropzoneFile").files.length;
                i++
            ) {
                let filei = document.querySelector(".dropzoneFile").files[i];
                //formData.append('files[' + i + ']', file);
                // dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
                //  this.file.push(filei);
                this.files.push(filei);
                // files.value.push(dropzoneFile.value);
            }
        },
        dropFile(e) {
            //alert("called drop called");
            for (var i = 0; i < e.dataTransfer.files.length; i++) {
                let filei = e.dataTransfer.files[i];
                //formData.append('files[' + i + ']', file);
                // dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
                //  this.file.push(filei);
                this.files.push(filei);
                // files.value.push(dropzoneFile.value);
            }
        },
    },
};
</script>

<template>
    <Layout>
       

        <div class="row" style="background-color: white">
            <!-- <h3 class="m-2">Profiles</h3> -->
            <!-- <div class="page-title-box d-sm-flex align-items-left justify-content-between"> -->
            <div class="page-title-box" style="padding: 5px">
                <div class="row" style="margin-left: 5px">
                   

                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">From</label>
                             <flatPickr class="form-control" :config="config" v-model="from_date"></flatPickr>
                            <!-- <input type="date" v-model="from_date" class="form-control mb-3" /> -->
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">To</label>
                            <flatPickr class="form-control" :config="config" v-model="to_date"></flatPickr>
                            <!-- <input type="date" v-model="to_date" class="form-control mb-3" /> -->
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Status</label>
                            <select v-model="progress_status_filter" class="form-select mb-3"
                                aria-label="Default select example">
                                <option value="-1">All</option>
                                <option value="New Work">New Work</option>
                                <option value="Assigned">Assigned</option>
                                <!-- <option value="Assigned Partial">Assigned Partial</option> -->
                                <option value="Writer Completed">Writer Completed</option>
                                <option value="Rework">Rework</option>
                                <option value="Ready for Client">Ready for Client</option>
                                <option value="Edits">Edits</option>
                                <option value="Submitted to Client">Submitted to Client</option>
                                <option value="Project Completed">Project Completed</option>
                                <option value="Refunded">Refunded</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Pymnt in</label>
                            <!-- <select v-model="payment_holder_name_id" @change="paymentaccounts" class="form-select mb-3"
                            aria-label="Default select example">
                            <option value="-1">All</option>
                            <option v-for="(user) of payment_holder_name_list" v-bind:key="user.id" :value="user.id">{{
                                user.name
                            }}</option>
                        </select> -->
                            <select required v-model="payment_holder_name_id" @change="paymentaccounts"
                                class="form-select mb-3" aria-label="Default select example">
                                <option value="-1">All</option>
                                <option v-for="profile of payment_holders_name_list" v-bind:key="profile.id"
                                    :value="profile.id">
                                    {{ profile.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <label for="firstName" class="form-label">in Acc.</label>
                        <!-- <select required v-model="payment_account_id" class="form-select mb-3"
                        aria-label="Default select example">
                        <option value="-1">All</option>
                        <option v-for="(profile) of payment_accounts_list" v-bind:key="profile.id" :value="profile.id">
                            {{ profile.type }} ({{
                                profile.details
                            }})
                        </option>
                    </select> -->
                        <select required v-model="payment_account_id" class="form-select mb-3"
                            aria-label="Default select example">
                            <option value="-1">All</option>
                            <option v-for="profile of payment_accounts_list" v-bind:key="profile.id"
                                :value="profile.id">
                                {{ profile.type }} ({{ profile.details }})
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-1">
                        <label for="firstName" class="form-label">At Profile</label>
                        <select required v-model="profile_id" class="form-select mb-3"
                            aria-label="Default select example">
                            <option value="-1">All</option>
                            <option v-for="profile of profiles" v-bind:key="profile.id" :value="profile.id">
                                {{ profile.full_name }} ({{ profile.short_name }})
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-2">
                        <label for="firstName" class="form-label">P. Status</label>
                        <select required v-model="payment_status" class="form-select mb-3"
                            aria-label="Default select example">
                            <option value="-1">All</option>
                            <option value="Not Paid">Not Paid</option>
                            <option value="Partial Paid">Partial Paid</option>
                            <option value="Fully Paid">Fully Paid</option>
                        </select>
                    </div>
                    <div class="col-lg-2">
                        <label for="firstName" class="form-label">Wrk Typ</label>
                        <select v-model="work_type" class="form-select mb-3" aria-label="Default select example">
                            <option value="-1">All</option>


                            <option v-for="item of login_user_work_type_array" v-bind:key="item.id" :value="item.id">{{
                                item.name
                            }}</option>
                            <!-- <option value="Premium Writing">Premium Writing</option>
                            <option value="Professional Writing">Professional Writing</option>
                            <option value="Technical">Technical</option>
                            <option value="Others">Others</option> -->
                        </select>
                    </div>
                </div>
               
                <div class="row"  style="margin-left: 5px">
                     <div class="col-lg-2">
                        <label for="firstName" class="form-label">Deal By Expert</label>
                        <input v-model="search_deal_by" type="text" placeholder=""
                            class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <!-- <i class=" ri-search-line"></i> -->
                    </div>
                    <div class="col-lg-2">
                        <label for="firstName" class="form-label">University</label>
                        <input v-model="search_university" type="text" placeholder=""
                            class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <!-- <i class=" ri-search-line"></i> -->
                    </div>
                    <div class="col-lg-2">
                        <label for="firstName" class="form-label">Client Name</label>
                        <input v-model="client_name" type="text" placeholder=""
                            class="form-control"  aria-describedby="button-addon2" />
                        <!-- <select v-model="client_name" class="form-select mb-3" aria-label="Default select example">
                            <option value="-1">All</option>
                            <option v-for="(client, index) of clients" v-bind:key="index" :value="client.client_name">
                                {{ client.client_name }}
                            </option>
                        </select> -->
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Order</label>
                            <select v-model="order_by" class="form-select mb-3" aria-label="Default select example">
                                <option value="createdat">Project Creation Date</option>
                                <!-- <option value="amount">Amount</option> -->
                                <option value="due_date">Real Due Date</option>
                                <option value="office_completion_date">
                                    Task Manager Completion Date
                                </option>
                                <!-- <option value="payment_recieved">Payment Recieved</option> -->
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Sort</label>
                            <select v-model="sort_by" class="form-select mb-3" aria-label="Default select example">
                                <option value="DESC">Desending</option>
                                <option value="ASC">Ascending</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <button type="submit" style="margin-top: 25px" class="btn btn-primary" @click="filter_data()">
                            FLTR
                        </button>
                        <button type="button" style="margin-top: 25px; margin-left: 10px" class="btn btn-primary"
                            @click="getUserData()">
                            CLR
                        </button>
                    </div>
                    <div class="col-lg-1">
                        <button class="btn btn-success" style="margin-top: 25px; float: right" @click="createuser">
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>

       
            <div class="row">
                <div class="card" style="padding: 0px;">
                    <div class="card-body m-2" style="padding: 0px;">
                        <div class="col-12" style="padding-bottom: 10px">
                            <b-alert v-model="registerSuccess" style="padding-bottom: 10px" variant="success"
                                dismissible>
                                {{ regSuccess }}
                            </b-alert>
                        </div>

                        <div class="table-responsive table-card fixTableHead" style="height: 480px !important;">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">Action</th>

                                        <th scope="col">ID <br>Code <br> Wrk Type</th>

                                        <th scope="col">Progress <br> Status</th>
                                          <th scope="col">Created At <br> Created By <br> Deal By</th>
                                        <th scope="col">
                                            Client name <br>
                                            At Profile
                                        </th>
                                      
                                        <th scope="col">
                                            Due Date<br />
                                            TM Finish Date
                                        </th>
                                        
                                        <th scope="col">
                                            T. Amount <br>
                                            Pay. Status <br />
                                            Pay. Recieved
                                        </th>
                                        <th scope="col" v-if="session_usertype == 'Super Admin'">
                                            <span ><br> Bonus (%)</span>
                                            <span ><br> T. Bonus</span>
                                            
                                        </th>
                                        <th scope="col">
                                            Pay. in<br />
                                            in Account<br>
                                            Pay. Notes
                                        </th>
                                        <!-- <th scope="col">Attachment</th> -->
                                       
                                        
                                        <th scope="col">P. Title <br> Uni. <br>Words</th>
    
                                        <!-- <th scope="col">Status</th> -->
                                      
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user of userslist" v-bind:key="user.id" :style="
                                        user.status == 'Active'
                                            ? 'background-color:#ddfcdd'
                                            : 'background-color:#ffe5e5'
                                    ">
                                        <td>
                                            <a style="margin-left: 10px;padding-bottom: 15px;" @click="
                                                updateuser(
                                                    user.id,
                                                    user.profile_id,
                                                    user.client_name,
                                                    user.client_contact,
                                                    user.client_email,
                                                    user.project_title,
                                                    user.amount,
                                                    user.currency,
                                                    user.customedue_date,
                                                    user.customeoffice_completion_date,
                                                    user.country,
                                                    user.work_type,
                                                    user.words,
                                                    user.ref_style,
                                                    user.status,
                                                    user.description,
                                                    user.payment_holder_name_id,
                                                    user.payment_recieved,
                                                    user.payment_status,
                                                    user.payment_account_id,
                                                    user.university,
                                                    user.deal_by,
                                                    user.bonus
                                                )
                                            "><i class="ri-edit-2-fill"></i></a>
                                            <a style="margin-left: 10px;padding-bottom: 15px;" v-if="session_usertype == 'Super Admin'" @click="deleteproject(user.id)"><i class=" ri-close-circle-fill"></i>
                                            </a> <br>
                                            <a @click="opensession(user.id)"><span class="badge bg-danger" style="
                                                font-size: 14px;
                                                color: white;
                                                margin-left: 5px;
                                            ">View</span></a>
                                            
                                        </td>
                                        <td>{{ user.id }}<br>{{ user.code }} <br><small style="color:blue;"> {{ user.Work_type_name }} </small></td>
                                        <td v-if="user.progress_status == 'New Work'"
                                            style="background-color: #d6ffbc; color: black">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Assigned'"
                                            style="background-color: #5d86ef; color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Writer Completed'"
                                            style="background-color: #bc9f05; color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Rework'"
                                            style="background-color: #88027b; color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Ready for Client'"
                                            style="background-color: #124b18; color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Edits'"
                                            style="background-color: #ffd3d3; color: black">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Submitted to Client'"
                                            style="background-color: #369f43; color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Project Completed'"
                                            style="background-color:  rgb(161 83 8); color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <!-- <td v-else-if="user.progress_status == 'Submitted to Client'"
                                            style="background-color:  rgb(70 75 24); color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td> -->
                                        <td v-else-if="user.progress_status == 'Refunded'"
                                            style="background-color:  black; color: white">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                        <td v-else-if="user.progress_status == 'Cancelled'"
                                            style="background-color: rgb(234 234 234); color: black">
                                            <span>{{ user.progress_status }}</span>
                                        </td>
                                         <td>
                                            <span > {{ user.customecreatedat }}</span>
                                            <br /><small style="color:brown;">{{ user.createdbyname }} </small>
                                            <br> <small style="color:blue;">{{ user.deal_by }} </small>
                                        </td>
                                        <td>
                                            {{ user.client_name }} <br>
                                           <small style="color:brown;"> {{ user.profile_full_name }}({{ user.profile_short_name }}) </small>
                                          
                                        </td>
                                        
                                        <td>
                                            <span style="color:red;font-weight:bold">{{ user.customedue_date }} </span><br />
                                            {{ user.customeoffice_completion_date }}
                                        </td>
                                       
                                         <td v-if="user.payment_status == 'Fully Paid'"
                                            style="background-color: green; color: white">
                                            {{ user.currency }} {{ user.amount }} <br>
                                            {{ user.payment_status }} <br />
                                            {{ user.currency }} {{ user.payment_recieved }}
                                        </td>
                                        <td v-else-if="user.payment_status == 'Partial Paid'"
                                            style="background-color: #5d86ef; color: white">
                                             {{ user.currency }} {{ user.amount }} <br>
                                             {{ user.payment_status }} <br />
                                            {{ user.currency }} {{ user.payment_recieved }}
                                        </td>
                                        <td v-else-if="user.payment_status == 'Not Paid'" style="background-color: grey; color: white">
                                             {{ user.currency }} {{ user.amount }} <br>
                                             {{ user.payment_status }} <br />
                                             {{ user.currency }} {{ user.payment_recieved }}
                                        </td>
                                         <td v-if="session_usertype == 'Super Admin'" >
                                             <small style="color:brown;"><br>{{ user.currency }} {{ user.bonus }}% </small>
                                             <small style="color:blue;"><br>{{ user.currency }} {{ (user.bonus * user.payment_recieved) /100 }} </small>    
                                        </td>
                                        <td>
                                            {{ user.payment_by_person_name }} <br>
                                            {{ user.payment_acount_type}}<br>
                                            {{ user.payment_notes }}
                                        </td>
                                        <td>{{ user.project_title }}

                                             <br> <small>{{ user.university }} <br>  {{ user.words }} words</small>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                            <!-- end table -->
                        </div>

                        <div class="mt-4">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <p style="float: left">Rows: {{ totalrows }}</p>

                                    <div class="page-title-right" style="float: right">
                                        <paginate v-model="page" :page-count="totalpage" :margin-pages="2"
                                            :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'"
                                            :container-class="'pagination'" :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--end tab-pane-->
                    </div>
                </div>
                <!--end col-->
            </div>
       
        <div class="row"></div>

        <!-- <profileshortcode-projectautocode->
    77-16-random
</profileshortcode-projectautocode-> -->


        <!-- start of create document edit modal -->
        <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
        <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
                        <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                        </b-alert>
                        <form @submit.prevent="updateuserprofile" id="updateDocumentform" enctype="multipart/form-data">
                            <div class="row g-3">
                                <div class="col-lg-2">
                                    <div class="mb-1">
                                        <label for="phonenumberInput" class="form-label">Title</label>
                                        <input required type="text" class="form-control" id="mobile"
                                            v-model="project_title" />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <label for="firstName" class="form-label">At Profile</label>
                                    <select required v-model="profile_id" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option v-for="profile of profiles" v-bind:key="profile.id" :value="profile.id">
                                            {{ profile.full_name }} ({{ profile.short_name }})
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-1">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">T. Amount</label>
                                        <input type="number" class="form-control" v-model="amount" required />
                                    </div>
                                </div>
                                <div class="col-lg-1">
                                    <label for="firstName" class="form-label">Currency</label>
                                    <select required v-model="currency" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="USD">USD</option>
                                        <option value="Rs.">Rs.</option>
                                        <option value="Dirham">Dirham</option>
                                    </select>
                                </div>
                                 <div class="col-lg-1">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">Deal By</label>
                                        <input type="text" class="form-control" v-model="deal_by" required />
                                    </div>
                                </div>
                                 <div class="col-lg-1">
                                    <label for="firstName" class="form-label">Bonus</label>
                                    <select required v-model="bonus" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="0">0%</option>
                                        <option value="0.5">0.5%</option>
                                        <option value="1">1%</option>
                                        <option value="1.5">1.5%</option>
                                        <option value="2">2%</option>
                                        <option value="2.5">2.5%</option>
                                        <option value="3">3%</option>
                                        <option value="3.5">3.5%</option>
                                        <option value="4">4%</option>
                                        <option value="4.5">4.5%</option>
                                        <option value="5">5%</option>
                                        <option value="6">6%</option>
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <label for="firstName" class="form-label">Due Date</label>
                                    <flat-pickr required v-model="due_date" :config="dateTimeConfig"
                                        class="form-control flatpickr-input">
                                    </flat-pickr>
                                </div>
                                <div class="col-lg-2">
                                    <label for="firstName" class="form-label">Office completion date</label>
                                    <flat-pickr required v-model="project_completion_date" :config="dateTimeConfig"
                                        class="form-control flatpickr-input">
                                    </flat-pickr>
                                </div>
                                <!--end col-->
                                <div class="col-lg-2">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">Client name</label>
                                        <input type="text" class="form-control" v-model="client_name" />
                                    </div>
                                </div>
                                <!--end col-->
                                <!-- <div class="col-lg-2">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">Client contact</label>
                                        <input type="text" class="form-control" v-model="client_contact" />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">Client email</label>
                                        <input type="email" class="form-control" v-model="client_email" />
                                    </div>
                                </div> -->
                                <div class="col-lg-2">
                                    <label for="firstName" class="form-label">Country</label>
                                    <select required v-model="country" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="Saudi Arab">Saudi Arab</option>
                                        <option value="UAE">UAE</option>
                                        <option value="USA">USA</option>
                                        <option value="UK">UK</option>
                                        <option value="kuwait">kuwait</option>
                                        <option value="Austrailia">austrailia</option>
                                        <option value="canada">Canada</option>
                                        <option value="Africa">Africa</option>
                                        <option value="Newzealand">Newzealand</option>
                                        <option value="Jorden">Jorden</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Bahrin">Bahrin</option>
                                        <option value="Oman">Oman</option>
                                        
                                    </select>
                                </div>

                                <div class="col-lg-2">
                                    <label for="firstName" class="form-label">Work Type</label>
                                    <select required v-model="work_type" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option v-for="item of login_user_work_type_array" v-bind:key="item.id"
                                            :value="item.id">{{ item.name }}</option>
                                        <!-- <option value="Writing">Writing</option>
                                        <option value="Premium Writing">Premium Writing</option>
                                        <option value="Professional Writing">
                                            Professional Writing
                                        </option>
                                        <option value="Technical">Technical</option>
                                        <option value="Others">Others</option> -->
                                    </select>
                                </div>
                                <div class="col-lg-1">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">Words</label>
                                        <input type="number" class="form-control" v-model="words" />
                                    </div>
                                </div>
                                <div class="col-lg-1">
                                    <label for="firstName" class="form-label">Ref. Style</label>
                                    <select required v-model="ref_style" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="APA">APA</option>
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <div>
                                        <label for="firstName" class="form-label">Project Status</label>
                                        <select required v-model="status" class="form-select mb-3"
                                            aria-label="Default select example">
                                            <option value="Active">Active</option>
                                            <option value="Disabled">Disabled</option>
                                        </select>
                                        <input type="hidden" v-model="updateuser_id" />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-1">
                                        <label for="emailInput" class="form-label">University</label>
                                        <input type="text" class="form-control" v-model="university" />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <ckeditor v-model="description" :editor="editor"></ckeditor>
                                </div>
                                <!--end col-->

                                <!--end col-->
                                <div class="col-lg-6">


                                    <div class="row">
                                        <div class="col-lg-2">
                                            <label for="firstName" class="form-label">Pmnt In</label>
                                            <select required v-model="payment_holder_name_id" @change="paymentaccounts"
                                                class="form-select mb-3" aria-label="Default select example">
                                                <option v-for="profile of payment_holders_name_list"
                                                    v-bind:key="profile.id" :value="profile.id">
                                                    {{ profile.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2">
                                            <label for="firstName" class="form-label">In AC.</label>
                                            <select required v-model="payment_account_id" class="form-select mb-3"
                                                aria-label="Default select example">
                                                <option v-for="profile of payment_accounts_list" v-bind:key="profile.id"
                                                    :value="profile.id">
                                                    {{ profile.type }} ({{ profile.details }})
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="mb-1">
                                                <label for="emailInput" class="form-label">Pay. Recevd</label>
                                                <input type="number" class="form-control" v-model="payment_recieved" />
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <label for="firstName" class="form-label">Pay. Status</label>
                                            <select required v-model="payment_status" class="form-select mb-3"
                                                aria-label="Default select example">
                                                <option value="Not Paid">Not Paid</option>
                                                <option value="Partial Paid">Partial Paid</option>
                                                <option value="Fully Paid">Fully Paid</option>
                                            </select>
                                        </div>
                                         <div class="col-lg-4">
                                            <div class="mb-1">
                                                <label for="emailInput" class="form-label">Pay. Notes</label>
                                                <input type="text" class="form-control" v-model="payment_notes" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <!-- <label for="firstName" class="form-label">Select attachment</label> -->
                                            <!-- <input type="file" ref="file" id="file" name="file"> -->
                                            <!-- <input type="file" id="file" class="form-control" ref="file"
                                                @change="onselect"> -->
                                            <DropZone @drop.prevent="dropFile" @change="selectedFile" />
                                            <ul class="list-unstyled mb-0" id="dropzone-preview">
                                                <div class="border rounded" v-for="(file, index) of files" :key="index">
                                                    <div class="d-flex p-2">
                                                        <div class="flex-grow-1">
                                                            <div class="pt-1">
                                                                <h5 class="fs-14 mb-1" data-dz-name="">
                                                                    {{ file.name }}
                                                                </h5>
                                                                <p class="fs-13 text-muted mb-0" data-dz-size="">
                                                                    <strong>{{ file.size / 1024 }}</strong> KB
                                                                </p>
                                                                <strong class="error text-danger"
                                                                    data-dz-errormessage=""></strong>
                                                            </div>
                                                        </div>
                                                        <div class="flex-shrink-0 ms-3">
                                                            <button data-dz-remove="" class="btn btn-sm btn-danger"
                                                                type="button" @click="deleteRecord(index)">
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <!-- Load More Buttons -->

                                        <div class="spinner-border" v-if="submitted" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <button type="submit" :disabled="submitted" class="btn btn-primary">
                                            {{ buttonname }}
                                        </button>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <a data-bs-toggle="modal" id="opensessionbutton" data-bs-target="#opensession"></a>
        <div class="modal fade" id="opensession" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">View Session History</h5>
                        <button type="button" id="closeopensessionbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                        </b-alert>

                        <div class="chat-wrapper d-lg-flex h-100 gap-1 mx-n4 mt-n4 p-1">
                            <div class="chat-leftsidebar h-100">
                                <div class="px-4 pt-4 mb-4">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1">
                                            <h5 class="mb-4">Threads</h5>
                                        </div>
                                        <!-- <div class="flex-shrink-0">
                      <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                        title="Add Contact">
                         Button trigger modal -->
                                        <!-- <button type="button" class="btn btn-soft-success btn-sm">
                          <i class="ri-add-line align-bottom"></i>
                        </button>
                      </div> -->
                                        <!-- </div> -->
                                        <!-- </div>
                  <div class="search-box">
                    <input type="text" class="form-control bg-light border-light" placeholder="Search here..." />
                    <i class="ri-search-2-line search-icon"></i>
                  -->
                                    </div>
                                </div>

                                <div class="chat-room-list" data-simplebar>
                                    <div class="d-flex align-items-center px-4 mb-2">
                                        <!-- <div class="flex-grow-1">
                      <h4 class="mb-0 fs-11 text-muted text-uppercase">
                        Session Threads
                      </h4>
                    </div> -->
                                        <!-- <div class="flex-shrink-0">
                      <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                        title="New Message">
                        Button trigger modal -->
                                        <!-- <button type="button" class="btn btn-soft-success btn-sm">
                          <i class="ri-add-line align-bottom"></i>
                        </button>
                      </div>
                    </div> -->
                                    </div>

                                    <div class="chat-message-list">
                                        <SimpleBar class="list-unstyled chat-list chat-user-list">
                                            <li class @click="chatUsername(-1)" :class="{ active: username == 'All' }">
                                                <a href="javascript: void(0);">
                                                    <div class="d-flex align-items-center">
                                                        <div
                                                            class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                            <!-- <div class="avatar-xxs" v-if="data.image">
                                <img :src="`${data.image}`" class="rounded-circle img-fluid userprofile" alt />
                              </div>-->
                                                            <div class="avatar-xxs">
                                                                <div
                                                                    class="avatar-title rounded-circle bg-success userprofile">
                                                                    .
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 overflow-hidden">
                                                            <p class="text-truncate mb-1">All</p>
                                                        </div>

                                                        <div class="flex-shrink-0"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class v-for="(data, index) of chatData" :key="data.id"
                                                @click="chatUsername(index)" :class="{ active: username == index }">
                                                <a href="javascript: void(0);">
                                                    <div class="d-flex align-items-center">
                                                        <div
                                                            class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                            <!-- <div class="avatar-xxs" v-if="data.image">
                                <img :src="`${data.image}`" class="rounded-circle img-fluid userprofile" alt />
                              </div>-->
                                                            <div class="avatar-xxs" v-if="!data.image">
                                                                <div
                                                                    class="avatar-title rounded-circle bg-success userprofile">
                                                                    {{ index + 1 }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 overflow-hidden">
                                                            <p class="text-truncate mb-1">
                                                                {{ data.progress_status }} ({{
                                                                    data.creadybyname
                                                                }})
                                                            </p>
                                                        </div>

                                                        <div class="flex-shrink-0">
                                                            <span class="badge badge-soft-dark rounded">{{
                                                                data.customecreatedat.split(" ")[0]
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </SimpleBar>
                                    </div>
                                    <!-- End chat-message-list -->
                                </div>
                            </div>
                            <!-- end chat leftsidebar -->
                            <!-- Start User chat -->
                            <div class="user-chat w-100 h-100 overflow-hidden">
                                <div class="chat-content d-lg-flex h-100">
                                    <!-- start chat conversation section -->
                                    <div class="w-100 h-100 overflow-hidden position-relative">
                                        <!-- conversation user -->
                                        <div class="position-relative h-100">
                                            <div class="p-3 user-chat-topbar">
                                                <div class="row align-items-center">
                                                    <div class="col-sm-4 col-8">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 d-block d-lg-none me-3">
                                                                <a href="javascript: void(0);"
                                                                    class="user-chat-remove fs-18 p-1"><i
                                                                        class="ri-arrow-left-s-line align-bottom"></i></a>
                                                            </div>
                                                            <div class="flex-grow-1 overflow-hidden">
                                                                <div class="d-flex align-items-center">
                                                                    <div
                                                                        class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                                        <img :src="
                                                                            profile
                                                                                ? profile
                                                                                : require('@/assets/images/users/multi-user.jpg')
                                                                        " class="rounded-circle avatar-xs" alt="" />
                                                                        <span class="user-status"></span>
                                                                    </div>
                                                                    <div class="flex-grow-1 overflow-hidden">
                                                                        <h5 class="text-truncate mb-0 fs-16">
                                                                            <a class="text-reset username"
                                                                                data-bs-toggle="offcanvas"
                                                                                href="#userProfileCanvasExample"
                                                                                aria-controls="userProfileCanvasExample">{{
                                                                                    currentselectedchatname
                                                                                }}</a>
                                                                        </h5>
                                                                        <p
                                                                            class="text-truncate text-muted fs-14 mb-0 userStatus">
                                                                            <small>{{
                                                                                currentselecteddatetime
                                                                            }}</small>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-sm-8 col-4">
                            <ul class="list-inline user-chat-nav text-end mb-0">
                              <li class="list-inline-item m-0">
                                <div class="dropdown">
                                  <button class="btn btn-ghost-secondary btn-icon" type="button"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <search-icon class="icon-sm"></search-icon>
                                  </button>
                                  <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                    <div class="p-2">
                                      <div class="search-box">
                                        <input type="text" class="form-control bg-light border-light"
                                          placeholder="Search here..." onkeyup="searchMessages()" id="searchMessage" />
                                        <i class="ri-search-2-line search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li class="list-inline-item d-none d-lg-inline-block m-0">
                                <button type="button" class="btn btn-ghost-secondary btn-icon"
                                  data-bs-toggle="offcanvas" data-bs-target="#userProfileCanvasExample"
                                  aria-controls="userProfileCanvasExample">
                                  <info-icon class="icon-sm"></info-icon>
                                </button>
                              </li>

                              <li class="list-inline-item m-0">
                                <div class="dropdown">
                                  <button class="btn btn-ghost-secondary btn-icon" type="button"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <more-vertical-icon class="icon-sm"></more-vertical-icon>
                                  </button>
                                  <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item d-block d-lg-none user-profile-show" href="#"><i
                                        class="ri-user-2-fill align-bottom text-muted me-2"></i>
                                      View Profile</a>
                                    <a class="dropdown-item" href="#"><i
                                        class="ri-inbox-archive-line align-bottom text-muted me-2"></i>
                                      Archive</a>
                                    <a class="dropdown-item" href="#"><i
                                        class="ri-mic-off-line align-bottom text-muted me-2"></i>
                                      Muted</a>
                                    <a class="dropdown-item" href="#"><i
                                        class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>
                                      Delete</a>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> -->
                                                </div>
                                            </div>
                                            <!-- end chat user head -->

                                            <div class="position-relative h-100" id="users-chat">
                                                <div class="chat-conversation h-100 p-3 p-lg-4" id="chat-conversation"
                                                    data-simplebar ref="current">
                                                    <ul class="list-unstyled chat-conversation-list">
                                                        <li v-for="(data, index) of chatMessagesData" :key="data.id"
                                                            :class="{
                                                                right:
                                                                    `${data.usertype}` === 'Bidder' ||
                                                                    `${data.usertype}` === 'Super Admin',
                                                                left:
                                                                    `${data.usertype}` !== 'Bidder' &&
                                                                    `${data.usertype}` !== 'Super Admin',
                                                            }" class="chat-list mb-4">
                                                            <div class="conversation-list">
                                                                <div class="chat-avatar">
                                                                    <div
                                                                        class="avatar-title rounded-circle bg-success userprofile">
                                                                        {{ index + 1 }}
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="chat-avatar" v-if="data.align !== 'right'">
                                  <img :src="profile ? profile : require('@/assets/images/users/user-dummy-img.jpg')"
                                    alt="" />
                                </div> -->
                                                                <div class="user-chat-content">
                                                                    <div class="ctext-wrap">
                                                                        <div class="ctext-wrap-content">
                                                                            <p class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span
                                                                                    style="font-weight: 600">Status:</span>
                                                                                {{ data.progress_status }}
                                                                            </p>
                                                                            <p class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span style="font-weight: 600">Crated
                                                                                    at:</span>
                                                                                {{ data.customecreatedat }}
                                                                            </p>
                                                                            <p class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span style="font-weight: 600">Crated
                                                                                    by:</span>
                                                                                {{ data.creadybyname }} (<span
                                                                                    v-if="data.usertype === 'Bidder'">
                                                                                    Operator </span><span
                                                                                    v-if="data.usertype !== 'Bidder'">
                                                                                    {{ data.usertype }} </span>)
                                                                            </p>
                                                                            <p v-if="
                                                                                data.progress_status == 'Assigned' ||
                                                                                data.progress_status == 'Edits' ||
                                                                                data.progress_status == 'Rework'
                                                                            " class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span style="font-weight: 600">Expiry
                                                                                    date for Writer:</span>
                                                                                {{ data.custome_writer_expiry }}
                                                                            </p>
                                                                            <p v-if="
                                                                                data.progress_status ==
                                                                                'Writer Completed'
                                                                            " class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span
                                                                                    style="font-weight: 600">Words:</span>
                                                                                {{ data.words }}
                                                                            </p>
                                                                            <p v-if="
                                                                                data.progress_status ==
                                                                                'Writer Completed'
                                                                            " class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span
                                                                                    style="font-weight: 600">Plagiarism:</span>
                                                                                {{ data.plagiarims }} %
                                                                            </p>
                                                                            <p v-if="
                                                                                data.progress_status ==
                                                                                'Writer Completed'
                                                                            " class="ctext-content"
                                                                                style="text-align: left">
                                                                                <span
                                                                                    style="font-weight: 600">Grammerly:</span>
                                                                                {{ data.grammerly }}
                                                                            </p>
                                                                            <p class="ctext-content"
                                                                                v-if="data.assigned_to !== -1"
                                                                                style="text-align: left">
                                                                                <span>Assigned to :</span>
                                                                                {{ data.assigned_name }}
                                                                            </p>
                                                                            <!-- <p class="ctext-content" style="font-size:14px;text-align: left;"><u>Notes:</u>
                                      </p> -->
                                                                            <!-- <p class="ctext-content" style="text-align: left;">{{ data.notes }}</p> -->
                                                                            <p class="ctext-content" style="
                                          font-size: 14px;
                                          text-align: left;
                                        ">
                                                                                <u>Description:</u>
                                                                            </p>
                                                                            <p class="ctext-content"
                                                                                style="text-align: left"
                                                                                v-html="data.description"></p>
                                                                            <p class="ctext-content" style="
                                          font-size: 14px;
                                          text-align: left;
                                        " v-if="data.attachment_name !== ''">
                                                                                <u>Attachment:</u>
                                                                            </p>
                                                                            <div class="ctext-content"
                                                                                v-if="data.attachment_name !== ''"
                                                                                style="text-align: left">
                                                                                <p v-for="(
                                            filetodownload, indexi
                                          ) of data.attachment_name.split(
                                              '<separator>'
                                          )" :key="indexi">
                                                                                    <a :href="
                                                                                        '' +
                                                                                        rootulr +
                                                                                        'project/download/' +
                                                                                        filetodownload
                                                                                    " target="_blank" style="
                                              color: yellow;
                                              cursor: pointer;
                                            ">
                                                                                        {{ filetodownload }}
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="dropdown align-self-start message-box-drop">
                                                                            <a class="dropdown-toggle" href="#"
                                                                                role="button" data-bs-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false">
                                                                                <i class="ri-more-2-fill"></i>
                                                                            </a>
                                                                            <div class="dropdown-menu">
                                                                                <a @click="
                                                                                    delete_single_session(data.id)
                                                                                " v-if="
    usertype !== 'Writer' &&
    (data.progress_status ==
        'Assigned' ||
        data.progress_status ==
        'Rework' ||
        data.progress_status == 'Edits')
" class="dropdown-item delete-item" href="#"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                                            </div>
                                                                        </div>
                                                                        <div class="conversation-name">
                                                                            <small class="text-muted time">{{
                                                                                data.time
                                                                            }}</small>
                                                                            <span
                                                                                class="text-success check-message-icon"><i
                                                                                    class="ri-check-double-line align-bottom"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                    <!-- end chat-conversation-list -->
                                                </div>
                                            </div>

                                            <!-- end chat-conversation -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--end row-->
    </Layout>
</template>
